@import "../../../../styles/theme.scss";

.termsBlock {
  overflow: scroll;
  max-height: 400px;
  padding-bottom: 15px;
}

.sketchField {
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  border: 1px solid $cGray3;
  border-radius: 8px;

  .sketchBlock {
    display: flex;
    background: $cGray4;
    border-radius: 8px;
  }
}

.footerButtonsContainer {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.btnText {
  font-family: "Lato", sans-serif;
}

.agreementInformation {
  white-space: pre-line;
}
