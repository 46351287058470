@import "../../styles/theme.scss";

.root {
  width: 100%;
}

.additionalInfo {
  border-bottom: 1px solid $cGray3;
  margin-bottom: 10px;
}
