.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.authorizedText {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;

  svg {
    color: #228c22;
  }
}

.paragraph {
  font-size: 12px;
  color: #667680;
}

.mappingContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  color: #667680;
  padding-bottom: 20px;
  border-bottom: 1px solid #b5b5b5;

  .inputContainer {
    width: 70%;
  }
}

.font12 {
  font-size: 12px;
}

.font14 {
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

.mappingParagraph {
  font-style: italic;
  margin: 0;
}

.skinCheckMappingLine {
  display: flex;
  gap: 100px;
  align-items: center;

  @media screen and (max-width: 820px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }

  .skinCheckSelectContainer {
    width: 300px;
  }

  .currencyInput {
    width: 100px;
  }
}

.appointmentConfigurationInput {
  min-width: 300px;
  width: 300px;
}

.appointmentBookingLinkLabel {
  width: 150px;
}

.minutesInput {
  max-width: 50px;
}

.appointmentConfigurationLine {
  display: flex;
  gap: 100px;

  @media screen and (max-width: 820px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }
}

.verticalBookingLine {
  @media screen and (min-width: 820px) {
    align-items: center;
  }
}

.noteContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h2 {
    font-size: 18px;
  }
}

.benefitsTitle {
  margin-bottom: 16px;
  color: #667680;
  font-weight: 700;
}

.redirectLink {
  color: white;
}
