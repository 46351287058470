.topNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  @media screen and (max-width: 1330px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .topNavInner {
    display: flex;
    align-items: center;
    gap: 16px;

    &:last-child {
      justify-content: end;
    }

    @media screen and (max-width: 1330px) {
      width: 100%;
    }
  }
}

.table {
  @media screen and (max-width: 1330px) {
    padding-top: 135px !important;
  }
}

.repeatMdTitle {
  font-size: 22px;
  color: #404040;
}

.clientWalletTableHead {
  width: 33%;
  color: black;
  font-size: 12px;
  font-weight: normal;
  padding: 4px 10px 4px 15px;
}

.clientWalletTableRow {
  border: 1px dotted #e6e9eb;
}

.tableWrapper {
  width: 100%;

  table {
    width: 100%;
    border-bottom: 1px solid #e6e9eb;
  }

  tbody {
    display: block;
    max-height: 300px;
    overflow-y: auto;
  }

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .noRecord {
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid #e6e9eb;
  }
}

.groupNameContainer {
  height: 60px;
  margin-bottom: 10px;
}

.groupName {
  font-weight: bold;
  margin: 0;
}

.rewardDate {
  font-style: italic;
  margin: 0;
}

.textCenter {
  text-align: center;
}

.verticalBaseline {
  vertical-align: baseline;
}

.bold {
  font-weight: bold;
}