@import "../../../../styles/theme.scss";

.container {
    display: flex;
    width: fit-content;
    gap: 32px;
    margin-left: 20px;
    margin-bottom: 5px;

    .buttons {
        &:hover {

            text-decoration: underline;
            text-decoration-color: $cPrimary ;
        }
    }
}