.simulatorContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.paramInput {
  border: 0;
}

.input {
  border-radius: 5px;
}
