@import "../../styles/theme.scss";

.root {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  border-radius: 8px;
  border: 1px solid $cGray1;
  height: 50px;
  min-height: 50px;
  background-color: $cWhite;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 0 15px 1px 15px;
  font-size: 16px;
  font-weight: 400;
  color: $cBlack;

  &:focus {
    &:not(.error):not(.readonly) {
      border-color: $cPrimary;
    }

    & + .maskTip {
      display: block;
    }
  }
}

.maskTip {
  display: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: $cGray2;
  pointer-events: none;
}

.error {
  border-color: $cRed;
  background-color: $cRedDim2;

  &::placeholder {
    color: $cRed;
  }

  & + .maskTip {
    color: $cRed;
  }
}

.disabled {
  background-color: $cGray4;
  pointer-events: none;
  cursor: default;
  user-select: none;
  color: $cGray0;
  -webkit-text-fill-color: $cGray0;

  &::placeholder {
    color: $cGray2;
    -webkit-text-fill-color: $cGray2;
  }
}

.readonly {
  cursor: default;
}

.smallInput {
  height: 36px;
  min-height: 36px;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 10px 1px 10px;
}

.mediumInput {
  height: 44px;
  min-height: 44px;
}
