.expandListButton {
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  color: #667680;
  font-size: 16px;
  background: transparent;
  border: none;
  padding: 16px 0;

  .expandIcon {
    display: inline-block;
    margin-top: 2px;

    &.expanded {
      transform: rotate(180deg);
      margin-top: 0;
    }
  }
}
