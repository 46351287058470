@import "../../../../../styles/theme.scss";

.root {
  max-height: 500px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  overscroll-behavior: contain;

  .log {
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid $cGray1;
    display: flex;
    flex-direction: column;

    .date {
      color: $cGray0Dark;
      font-size: 13px;
    }

    .userName {
      font-weight: bold;
    }
  }
}
